(function() {
    const types = ['button', 'a', 'input', 'textarea', 'select', 'label'];

    const add = e => {
        let node = e.target;

        while (node && node.tagName && types.indexOf(node.tagName.toLowerCase()) === -1) {
            node = node.parentNode;
        }

        if (node && node.classList) {
            node.classList.add('no-focus');
        }

    };

    const remove = e => {
        let node = e.target;

        while (node && node.classList && !node.classList.contains('nofocus')) {
            node = node.parentNode;
        }

        if (node && node.classList) {
            node.classList.remove('no-focus');
        }

    };

    function go() {
        document.addEventListener('mousedown', add, false);
        document.addEventListener('touchstart', add, true);
        document.addEventListener('blur', remove, true);
        document.addEventListener('touchend', remove, true);
    }

    if (document.readyState == 'loading') {
        document.addEventListener('DOMContentLoaded', go, false);
    } else {
        go();
    }
})();
