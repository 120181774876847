let faderContainer = document.querySelector('.fader');

if (faderContainer) {
    let faderSlides = faderContainer.querySelectorAll('.fader-slide'),
        counter = 1,
        numSlides = faderSlides.length;

    faderSlides = Array.prototype.slice.call(faderSlides);
    faderSlides[0].classList.add('fader-slide-active');

    setInterval(() => {
        faderSlides.forEach(slide => {
            if (slide.classList.contains('fader-slide-active')) {
                slide.classList.remove('fader-slide-active');
            }
        });

        faderSlides[counter].classList.add('fader-slide-active');
        counter++;

        if (counter >= numSlides) {
            counter = 0;
        }
    }, 5000);
}
