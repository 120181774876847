let navToggle = document.querySelector('.mobile-nav-toggle');

function isHidden(elem) {
    let style = window.getComputedStyle(elem);
    return (style.display === 'none');
}

if (navToggle) {
    //console.log('is nav toggle');
    let mobileToggleVisible = isHidden(navToggle);

    if (mobileToggleVisible) {
        //console.log('nav toggle visible');
        navtoggle.nextElementSibling.setAttribute('aria-hidden', 'true');
    }

    navToggle.addEventListener('click', () => {
        if ( navToggle.classList.contains('active') ) {
            navToggle.classList.remove('active');
            navToggle.nextElementSibling.classList.remove('active');
            navToggle.nextElementSibling.setAttribute('aria-hidden', 'true');
        } else {
            navToggle.classList.add('active');
            navToggle.nextElementSibling.classList.add('active');
            navToggle.nextElementSibling.setAttribute('aria-hidden', 'false');
        }
    });
}
